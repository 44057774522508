body {
  font-family: Arial, sans-serif;
  background-color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-width: 500px;
  color: #ccc;
  height: 100vh;
}

.container {
  background-color: #181818;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: auto;
  width: 300px;
}

h2 {
  margin-top: 0;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #ccc;
}

.form-group input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;background-color: #3a3a3a;
  border-radius: 4px;
}

.form-group button {
  width: 100%;
  padding: 10px;
  background-color: #e82276;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #47e7ff;
  color: #181818;
}



table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #282828;
}


.flex-parent-element {
  display: flex;
  width: 100%;
}

.flex-child-element {
  flex: 1;
  margin: 10px;
}

.flex-child-element:first-child {
  margin-right: 20px;
}

img {
  border-radius: 50%;
}